import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PodcastLecture from "./components/podcasts/PodcastLecture";
import Main from "./components/main/Main";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/lecture/:key" element={<PodcastLecture />} />
                <Route path="/" element={<Main />} />
            </Routes>
        </Router>
    );
}

export default App;
