import React from 'react';
import Card from "./Card";
import {featuresData} from "../../data/data";
import TextImage from "../layouts/TextImage";
import {psycho} from "../../assets";

const Expertise = () => {
    return (
        <section
            id="expertise"
            className={"w-full py-20 " +
                "xxs:mt-[80vh] "+
                "xs:mt-[20vh] "+
                "lg:border-b-[1px] lg:border-b-black mdl:mt-0"}
        >
            <TextImage
                des={[
                    "Une séance de psychothérapie se déroule le plus généralement de manière traditionnelle : " +
                    "le thérapeute et le client dialoguent face à face. Le format que peut prendre la séance dépend " +
                    "ensuite des méthodes  utilisées.",
                    "Dans tous les cas, le thérapeute et le patient doivent entretenir une relation de confiance et de " +
                    "sécurité. Professionnel de l’écoute et de la psychologie, le thérapeute invite le patient à s’exprimer.",
                    "Le patient et le thérapeute nouent une \"alliance thérapeutique\" appelée également \"contrat " +
                    "thérapeutique\" qui permet au patient d’exprimer ce qu’il ressent sans craindre d’être jugé et " +
                    "critiqué. Il peut ainsi mettre des mots sur ses problèmes, ses émotions, sa souffrance, son vécu. " +
                    "Cette confrontation avec lui-même lui permet de se regarder différemment, d’aborder ses problèmes " +
                    "et sa vie en général sous un autre angle pour y puiser un soulagement, voire une guérison.",
                    "Mon approche est essentiellement sur la thérapie corporelle et, dans ce cadre, j’utilise de nombreux outils."
                    ]
                }
                title={"Outils de Psychothérapie"}
                position={"left"}
                media={psycho}
                flagVertical={false}
                id = {"expertise"}
                ></TextImage>

            {
            <div className="grid grid-cols-1 mt-24 md:grid-cols-2 xl:grid-cols-3 gap-6 xl:gap-20 xl:mb-0 ">
                {featuresData.map((item) => (
                    <Card item={item} key={item.id}  />
                ))}
            </div>
            }
        </section>
    );
};

export default Expertise;