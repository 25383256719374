import {navLinksdata} from "../../constants/index";
import {Link} from "react-scroll";
import React, {useState} from "react";
import {FaBars, FaXmark} from "react-icons/fa6";


const Navbar = () => {
    const [showMenu, setShowMenu] = useState(false)

    return (
        <div
            className={
                "w-full h-24 sticky top-0 z-50 bg-gradient-to-b from-[#7E736D] via-[#45342A] to-[#3d3030] mx-auto flex justify-between " +
                "items-center font-titleFont"

            }>
            <div></div>
            <div className={"mr-14"}>
                <ul className="hidden mdl:inline-flex items-center gap-6 lg:gap-10">
                    {navLinksdata.map(({_id, title, link, subLinks}) => (
                        <li
                            className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-designColor duration-300"
                            key={_id}
                        >
                            <Link
                                activeClass="active"
                                to={link}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                {title}
                            </Link>
                        </li>
                    ))}
                </ul>
                <span
                    onClick={() => setShowMenu(!showMenu)}
                    className="text-xl mdl:hidden bg-black w-10 h-10 inline-flex items-center justify-center rounded-full text-designColor cursor-pointer"
                >
                    <FaBars className={"hover:cursor-pointer"}/>
                </span>
                {showMenu && (
                    <div
                        className="w-[80%] h-screen overflow-scroll absolute top-0 left-0 bg-gray-900 p-4 scrollbar-hide">
                        <div className="flex flex-col gap-8 py-2 relative">
                            <div>

                            </div>
                            <ul className="flex flex-col gap-4">
                                {navLinksdata.map((item) => (
                                    <li
                                        key={item._id}
                                        className="text-base font-normal text-gray-400 tracking-wide cursor-pointer hover:text-navbarHoverColor duration-300"
                                    >
                                        <Link
                                            onClick={() => setShowMenu(false)}
                                            activeClass="active"
                                            to={item.link}
                                            spy={true}
                                            smooth={true}
                                            offset={-70}
                                            duration={500}
                                        >
                                            {item.title}
                                        </Link>
                                    </li>
                                ))}
                            </ul>

                            <span
                                onClick={() => setShowMenu(false)}
                                className="absolute top-4 right-4 text-gray-400 hover:text-navbarHover duration-300 text-2xl cursor-pointer"
                            >
                                <FaXmark/>
                            </span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Navbar