import React, {useState} from 'react';
import {FaArrowUp} from "react-icons/fa6";

const GoUpButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };
    window.addEventListener("scroll", handleScroll);
    return (
        <button
            className={`${
                isVisible ? "block" : "hidden"
            } fixed bottom-8 right-8 bg-designColor text-white rounded-full w-12 h-12 flex items-center justify-center cursor-pointer hover:bg-designColorHover transition-colors duration-300`}
            onClick={scrollToTop}
        >
            <FaArrowUp className={"w-6 h-9"}/>
        </button>
    );
};



export default GoUpButton;