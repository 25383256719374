import React from 'react';
import {featuresData} from "../../data/data";
import TextImage from "../layouts/TextImage";

const Tools = () => {
    return (
        <div>
        {
            featuresData.map((item) => (
                <TextImage
                    id = {item.id}
                    des={item.des}
                    title={item.title}
                    position={"left"}
                    media={item.media}
                    flagVertical={false}
                    >
                </TextImage>
            ))
        }
        </div>
    );
};

export default Tools;

