
export function handleEmailClick() {
    const email = "contact@jennybacry.fr";


    // Redirection to mail service
    const sendTo = `mailto:${email}`;
    window.location.href = sendTo
}

export function handlePhoneClick(){
    const phone = "0613092110";
    // Redirection phone service
    const phoneTo = `tel:${phone}`;
    window.location.href = phoneTo
}