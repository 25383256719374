import React from 'react';
import {jenny1} from "../../assets";
import TextImage from "../layouts/TextImage";

const Forwho = () => {
    return (
        <TextImage id={"forwho"}
                   title={"Pour qui ?"}
                   des={["Vous pouvez contacter Jenny Bacry si vous êtes un adolescent, un adulte,\n" +
                        "                        un couple ou une famille en difficulté, en cas de : ",
                            [
                                "problèmes de couple",
                                "problèmes familiaux",
                                "phobies de toutes sortes",
                                "douleurs chroniques",
                                "dépression",
                                "anxiété",
                                "crises de panique",
                                "stress",
                                "mal-être",
                                "migraines",
                                "insomnies",
                                "cauchemars",
                                "troubles obsessionnels",
                                "troubles du comportement",
                                "problèmes de  confiance",
                                "problèmes de  concentration",
                                "manque de motivation",
                                "besoin d'aide à la performance",
                                "accompagnement des maladies graves",
                                "coaching",
                                "développement personnel",
                                "lutte contre la douleur",
                                "troubles sexuels (impuissance, frigidité, stérilité)",
                                "troubles du comportement alimentaire (boulimie, anorexie)",
                                "alcoolisme",
                                "maladie de peau",
                                "traumatismes (accident, deuil, séparation, …)"
                            ],
                        "La première séance déterminera les objectifs de travail et les outils utilisés dans un cadre chaleureux et bienveillant. "
                        ]}
                   media={jenny1}
                   flagVertical={true}
                   position = {"left"}
        />
    )
};

export default Forwho;