import React from 'react';
import Title from "../layouts/Title";
import Text from "./Text";
import Image from "./Image";

const TextImage = ({id, title, des, media, flagVertical, position}) => {
    const idIsUndefined = "mr-0 ml-0 lg:mr-32 lg:ml-32"
    const  idNotUndefined = "w-full py-20"
    return (
        <section
            id={id}
            className={id === undefined ? idIsUndefined : idNotUndefined}
        >
            <div className={"flex flex-col mr-6 ml-6"}>
                <Title title={title}/>
                <div className={"flex flex-col gap-6 mt-2 lg:flex-row"}>
                {position === "left" ?
                    <>
                        <Text des={des}></Text>

                            <Image media={media} flagVertical={flagVertical}></Image>

                    </> :
                    <>
                        <Image media={media} flagVertical={flagVertical}></Image>
                        <Text des={des}></Text>
                    </>
                }
                </div>
            </div>


        </section>
    );
};

export default TextImage;