import React from 'react';
import {Cursor, useTypewriter} from "react-simple-typewriter";
import Contact from "./Contact";

const Banner1 = () => {
    const [text] = useTypewriter({
        words: ["Hypnose Ericksonienne et médicale",  "DRYME", "PNL", "Méditation pleine conscience",  "Equithérapie", "EFT",
            "Danse thérapie", "Constellations familiales individuelles "],
        loop: true,
        typeSpeed: 20,
        deleteSpeed: 30,
        delaySpeed: 2000
    })
    return (

        <div className={"w-full flex flex-col gap-20 text-center"}>
            <div className={"flex flex-col gap-5"}>
                <h1 className={"mt-[100vh] text-4xl text-textColor font-extrabold " +
                    "xs:mt-[44vh] " +
                    "xsl:mt-[30vh] " +
                    "sm:mt-[20vh] " +
                    "mdl:text-6xl mdl:mt-[10vh]"}
                >
                    Cabinet de Thérapies Brèves
                </h1>




                <h2 className={"text-2xl lgl:text-4xl text-designColor capitalize font-extrabold"}>par Jenny Bacry</h2>
                <h2 className={"text-1xl lgl:text-2xl font-bold mt-2 italic text-textColor"}>
                    {text}
                    <Cursor
                        cursorBlinking="false"
                        cursorStyle={"|"}
                        cursorColor={"#ffffff"}
                    />
                </h2>
            <div className={"xl:mt-4 mx-auto text-textColor"}>
                <Contact/>
            </div>
            </div>
        </div>



    );
};


export default Banner1;