import React from 'react';
import Title from "../layouts/Title";

const Resume = () => {
    return (
        <section
            id={"resume"}
            className="w-full py-20 px-4 "
        >
            <div>
                <Title title={"Parcours universitaire et formations"}/>
                <div className={"w-full flex flex-wrap"}>
                    <ul className={"px-8"}>
                        <li className={"pb-1"}>{"\u2022"}Formation à l’équithérapie (Ecurie Audrey Blumenfeld, 2023)</li>
                        <li className={"pb-1"}>{"\u2022"}DU d'Expertise légale en Psychologie et Psychopatologie de l'enfant et de
                            l'adolescent (René Descartes, 2020)
                        </li>
                        <li className={"pb-1"}>{"\u2022"}DU de Psychotraumatologie (René Descartes, 2019)</li>
                        <li className={"pb-1"}>{"\u2022"}Formation Danse-Thérapie (Artec, 2012)</li>
                        <li className={"pb-1"}>{"\u2022"}Formation au Mindfulness (MBCT, 2011)</li>
                        <li className={"pb-1"}>{"\u2022"}Formation à la PNLH (Programmation Neuro Linguistique et Hypnose, 2010)</li>
                        <li className={"pb-1"}>{"\u2022"}DU d’Hypnose médicale (Hôpital de la Pitié Salpêtrière 2009)</li>
                        <li className={"pb-1"}>{"\u2022"}Formation à l’Hypnose Ericksonienne (Académie de Recherches sur les
                            Connaissances en Hypnose Ericksonienne ARCHE, 2009)
                        </li>
                        <li className={"pb-1"}>{"\u2022"}Formation à l’utilisation des médiateurs thérapeutiques. Orientation comportementaliste (Fondation pour l'Enfance, 2004)
                        </li>
                        <li className={"pb-1"}>{"\u2022"}
                            Diplôme d'Etudes Approfondies (DEA) de Psychopathologie (Université Paris VIII, 2001).
                        </li>
                        <li className={"pb-1"}>{"\u2022"}
                            Formation à la prise en charge des migrants. Orientation Ethnoclinique (Centre Georges Devereux, 2000)
                        </li>
                        <li className={"pb-1"}>{"\u2022"}
                            Diplôme d'Etudes Supérieures Spécialisées (DESS) de Psychologie clinique et
                            sociale. Orientation Psychanalytique (Université Paris VII, 1998)
                        </li>

                    </ul>
                </div>
            </div>
            <div className={"mt-[10vh]"}>
                <Title title={"Parcours professionnel"}/>
                <ul className={"px-8"}>
                    <li>{"\u2022"}Équithérapeute (Ecurie Audrey Blumenfeld)</li>
                    <li>
                        {"\u2022"}Psychologue(Association Parcours d'Exil)
                        <p className={"px-12"}><i>Entretiens thérapeutiques auprès des migrants ayant été victimes de
                            torture</i></p>
                    </li>
                    <li>
                        {"\u2022"}Psychologue référente (Association Thélèmythe)
                        <p className={"px-12"}><i>Accompagnments thérapeutiques de jeunes adolescents et jeunes majeurs
                            placés à l'ASE sous mandat de justice</i></p>
                    </li>
                    <li>
                        {"\u2022"}Psychologue (Association AEF)
                        <p className={"px-12"}><i>Evaluations de situations critiques pour lesquelles un éducateur a été
                            mandaté.</i></p>
                        <p className={"px-12"}><i>Accompagnements thérapeutiques de familles dysfonctionnantes.</i></p>
                    </li>
                    <li>
                        {"\u2022"}Chef de Service éducatif en Placement familial (OSE)
                        <p className={"px-12"}><i>Protection de l’enfance (enfants placés sur mandats de justice)</i>
                        </p>
                        <p className={"px-12"}><i>Pratiques : Encadrement d’une équipe de 8 travailleurs sociaux et de
                            60
                            familles d’accueil.</i></p>
                        <p className={"px-12"}><i>Evaluation des pratiques. Projets individualisés des enfants.
                            Synthèses.
                            Recrutement des familles d’accueil. Travail autour de l’évaluation de la dys-parentalité
                            avec les familles naturelles.</i>
                        </p>

                    </li>
                    <li>
                        {"\u2022"}Psychologue en Prévention de la délinquance, de la toxicomanie et de l’alcoolisme
                        (OPEJ)
                        <p className={"px-12"}><i>Pratiques: Médiations familiales. Animations groupes de paroles jeunes
                            et
                            groupes de paroles intergénérationnels. Suivis individuels. Participation aux projets
                            Prévention de la ville, impliquant un travail en réseau.</i>
                        </p>

                    </li>
                    <li>
                        {"\u2022"}Psychologue clinicienne pour un Service d'entraide (CASIP)
                        <p className={"px-12"}><i>Soutien psychologique auprès de personnes isolées, âgées et /ou
                            malades.</i></p>
                        <p className={"px-12"}><i>Pratiques: Médiations familiales à domicile. Médiations
                            institutionnelles,
                            suivis individuels.</i>
                        </p>

                    </li>
                    <li>
                        {"\u2022"}Consultante psychologue et médiatrice culturelle.
                        <p className={"px-12"}><i>Travail avec le réseau d'institutions sociales autour des
                            problématiques
                            d'intégration culturelle</i></p>
                        <p className={"px-12"}><i>Pratiques: Entretiens individuels et familiaux auprès des personnes
                            issues
                            de l’immigration. Formation des travailleurs sociaux à une sensibilisation culturelle de
                            l’écoute .Médiations institution-particuliers.</i>
                        </p>

                    </li>
                    <li>
                    </li>
                    <li>
                        {"\u2022"}Formatrice consultante pour le personnel hospitalier et les travailleurs sociaux (St
                        Anne, Hôpital intercommunal de Montreuil, ...)
                        <p className={"px-12"}><i>Thèmes : Maltraitance, éthnopsychiatrie, prise en charge du public
                            précaire, toxicomanie, alcoolisme, psychologie de l’enfant et des adolescents.</i>
                        </p>

                    </li>
                </ul>
            </div>


        </section>
    );
};

export default Resume;