import React from 'react'

const Title = ({title}) => {
    return (
        <div className="flex flex-col gap-4 font-titleFont mb-4">
            <h1 className="text-2xl md:text-2xl text-textColor font-bold">{title}</h1>
        </div>
    );
}

export default Title