import React from 'react';
import {FaEnvelope, FaPhone} from "react-icons/fa6";
import {handleEmailClick, handlePhoneClick} from "../../functions/functions";
import {doctolib} from "../../assets";



const Contact = () => {
    return (
        <div className="flex flex-col xl:flex-row gap-6 lgl:gap-0 justify-between">
            <div>
                <h2 className="text-xs lgl:text-sm uppercase font-titleFont mb-4">
                    Contact
                </h2>
                <div className="flex gap-4">
                    <span className="bannerIcon" onClick={handlePhoneClick}>
                        <FaPhone/>
                    </span>
                    <span className="bannerIcon" onClick={handleEmailClick}>
                        <FaEnvelope/>
                    </span>
                    <span className="bannerDoctoIcon">
                        <a href="https://www.doctolib.fr/psychologue/paris/jenny-bacry" target={"_blank"} rel="noreferrer noopener">
                        <img
                        src={doctolib}
                        alt="doctolib"
                        />
                        </a>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Contact;