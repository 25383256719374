import React from 'react';

const Image = ({media, flagVertical}) => {
    return (
        <div className={"w-full lg:w-1/4"}>
            {
                flagVertical ?
                    <div className={"mx-auto w-[120px] h-auto lg:w-[150px] "}>
                        <img src={media}  alt={"jenny"}/>
                    </div>
                 :
                    <div className={"mx-auto h-[10px] w-1/2 lg:w-full mb-10"}>
                        <img src={media} alt={"jenny"} />
                    </div>

            }
        </div>
    );
};

export default Image;