/* Writing rules. If there are several links do this :

Example :
  {
    id: 1,
    title: "Lorem",
    guests: "Lorem",
    links:{
        1: "first link",
        2: "second link"
        3: "...."
        }
    }

If there is only one link do this :

Example :
  {
    id: 1,
    title: "Lorem",
    guests: "Lorem",
    link: "link"

    }
*/
export const podcastsData = [
    {
        id: 1,
        title: "Auto-hypnose",
        guests: "Kevin Finel et Jean Dupré",
        links:{
            1: "spitz.png",
            2: "02_Auto_Hypnose.mp3",
        }
    },
    {
        id: 2,
        title: "Chiropractie",
        guests: "Colleen Prendergast",
        links:{
            1: "01_Chiropractie.mp3",
            2: "02_Chiropractie.mp3",
        }
    },
    {
        id: 3,
        title: "Feng Shui",
        guests: "Arnaud Devillers",
        links:{
            1: "01_Feng_Shui.mp3",
            2: "02_Feng_Shui.mp3",
        }
    },
    {
        id: 4,
        title: "Danse thérapie",
        guests: "France Schott Billman",
        links:{
            1: "01_Danse_therapie.mp3",
            2: "02_Danse_therapie.mp3",
        }
    },
    {
        id: 5,
        title: "Ethnopsychiatrie",
        guests: "Tobie Nathan)",
        links:{
            1: "01_Ethnopsychiatrie.mp3",
            2: "02_Ethnopsychiatrie.mp3",
        }
    },
    {
        id: 6,
        title: "Hypnose et addictions",
        guests: "Jean-Marc Benaïem",
        links:{
            1: "01_Hypnose_et_addictions.mp3",
            2: "02_Hypnose_et_addictions.mp3",
        }
    },
    {
        id: 7,
        title: "Hypnose et traumatisme",
        guests: "Corinne Van Loye",
        links:{
            1: "01_Hypnose_et_traumatisme.mp3",
            2: "02_Hypnose_et_traumatisme.mp3",
        }
    },
    {
        id: 8,
        title: "Mindfulness",
        guests: "Christophe André",
        links:{
            1: "01_Mindfulness.mp3",
            2: "02_Mindfulness.mp3",
        }
    },
    {
        id: 9,
        title: "Naturopathie",
        guests: "Véronique Choutaud",
        links:{
            1: "01_Naturopathie.mp3",
            2: "02_Naturopathie.mp3",
        }
    },
    {
        id: 10,
        title: "Psychanalyse",
        guests: "Gérard Huber",
        links:{
            1: "01_Psychanalyse.mp3",
            2: "02_Psychanalyse.mp3",
        }
    },
    {
        id: 11,
        title: "Psychologie transgénérationnelle",
        guests: "Christine Ulivucci",
        links:{
            1: "01_Psychologie_transgenerationnelle.mp3",
            2: "02_Psychologie_transgenerationnelle.mp3",
        }
    },
    {
        id: 12,
        title: "Qu'est-ce que la psychothérapie",
        guests: "Thierry Janssen",
        links:{
            1: "01_Psychotherapie.mp3",
            2: "02_Psychotherapie.mp3",
        }
    },
    {
        id: 13,
        title: "Qu'est-ce que l'hypnose",
        guests: "Pascale Haag",
        links:{
            1: "01_Qu_est_ce_que_l_hypnose.mp3",
            2: "02_Qu_est_ce_que_l_hypnose.mp3",
        }
    },
    {
        id: 14,
        title: "La médecine traditionnelle chinoise",
        guests: "Jean-François Cludy",
        links:{
            1: "01_Medecine_traditionnelle_chinoise.mp3",
            2: "02_Medecine_traditionnelle_chinoise.mp3",
        }
    },
    {
        id: 15,
        title: "Guérir de la Shoah",
        guests: "Nathalie Zajde",
        links:{
            1: "01_Guerir_de_la_Shoah.mp3",
            2: "02_Guerir_de_la_Shoah.mp3",
        }
    },
    {
        id: 16,
        title: "Le sentiment d'impuissance",
        guests: "Corinne Van Loye",
        links:{
            1: "01_Le sentiment_d_impuissance.mp3",
            2: "02_Le sentiment_d_impuissance.mp3",
        }
    },
    {
        id: 17,
        title: "L'hypnose avec les enfants I",
        guests: "Isabelle Célestin",
        links: "L_hypnose_avec_les_enfants_I.mp3",
    },
    {
        id: 18,
        title: "L'hypnose avec les enfants II",
        guests: "Catherine Bouchara ",
        links : "L_hypnose_avec_les_enfants_II.mp3"

    },
    {
        id: 19,
        title: "Techniques du zéro mental",
        guests: "Frédéric Vincent",
        links: "..."
    }

]