import React from 'react'
import {cabinet, cabinet1,} from "../../assets/index";

const Banner2 = () => {
    return (
        <div className="w-full flex justify-center items-center relative pr-4 mx-auto">
            <div className={"flex flex-row gap-4"}>
                <img
                    className="w-auto h-[160px] md:w-auto md:h-[160px] lgl:w-auto lgl:h-[200px] z-10"
                    src={cabinet}
                    alt="cabinet"
                />
                <img
                    className="w-auto h-[160px] md:w-auto md:h-[160px] lgl:w-auto lgl:h-[200px] z-10"
                    src={cabinet1}
                    alt="cabinet1"
                />
            </div>


        </div>
    );
}

export default Banner2