import {Link} from "react-router-dom";

const Podcast = ({title, guests, links}) => {

    return (
        <div className={"font-smartphoneFont md:flex md:mt-2 md:font-bodyFont"}>
            <h1>
                <strong>{title}</strong>
            </h1>
            <span className={"pl-1 flex"}>
                <p> <i>(invité : {guests})</i> :</p>

                <ul>
                     <li>
                {typeof links === "string" ? (
                        <Link className={"pl-1"} target="_blank" key={links} to={`/lecture/`+links}>
                            Ici
                        </Link>

                ) : (
                    links && Object.keys(links).map((key,index) => (
                        <Link className={"pl-1"} target="_blank" key={key} to={`/lecture/`+links[key]}>
                            Partie {key}
                            {index !== Object.keys(links).length - 1 && ","}
                        </Link>



                    ))
                )}
                     </li>
                </ul>
            </span>
        </div>
    );
};

export default Podcast;