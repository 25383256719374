import React from 'react';
import Title from "../layouts/Title";
import Podcast from "./Podcast";
import {podcastsData} from "../../data/podcastsDatas";


const PodcastsList= () => {
    return (
        <section
            id={"podcast"}
            className="w-full py-20 border-b-[1px] border-b-black"
        >
            <Title title={"Podcasts"}/>
            <div className="">
                {podcastsData.map((item) => (
                    <Podcast item={item} key={item.id} title={item.title} guests={item.guests} links={item.links}   />
                ))}
            </div>


        </section>
    );
};

export default PodcastsList;