import React from 'react';
import { useParams } from 'react-router-dom';

const PodcastLecture = () => {
    const { key } = useParams();

    return (
        <section className={"flex flex-col items-center justify-center bg-bodyColor h-screen w-screen"}>
            <div>
                <audio controls className="mx-auto">
                    <source src={`/${key}`} type="audio/mpeg"/>
                    <p className={"text-lightText"}>Votre navigateur ne prend pas en charge la lecture audio.</p>
                </audio>
            </div>
        </section>
    );
};

export default PodcastLecture;
