import React from 'react'
import Contact from "../banner/Contact";
import {Link} from "react-scroll";


const Footer = () => {
    return (
        <div
            className="w-full px-4 py-20 h-auto border-b-[1px] bg-gradient-to-b from-[#3d3030] via-[#45342A] to-[#7E736D]  border-b-black grid grid-cols-1 md:grid-cols-3 lgl:grid-cols-4 gap-8">
            <div className="w-full h-full flex flex-col gap-8">
                <Contact/>

            </div>

            <div className="w-full h-full">
                <h3 className="text-xl uppercase text-designColor tracking-wider">
                    Outils de psychothérapie
                </h3>
                <ul className="flex flex-col gap-4 font-titleFont font-medium py-6 overflow-hidden">
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
                <Link
                    activeClass="active"
                    to={1}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                >
                    Hypnose Ericksonienne et médicale
                </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
                 <Link
                     activeClass="active"
                     to={2}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                 >
                        DRYME
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
                 <Link
                     activeClass="active"
                     to={3}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                 >
              PNL
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
                 <Link
                     activeClass="active"
                     to={4}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                 >
              Mindfulness
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
                 <Link
                     activeClass="active"
                     to={5}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                 >
              Équithérapie
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
                 <Link
                     activeClass="active"
                     to={6}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                 >
              EFT
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
                 <Link
                     activeClass="active"
                     to={7}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                 >
              Danse Thérapie
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
                 <Link
                     activeClass="active"
                     to={2}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                 >
                        Constellations familiales individuelles
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                </ul>
            </div>
            <div className="w-full h-full">
                <h3 className="text-xl uppercase text-designColor tracking-wider">
                    Menu
                </h3>
                <ul className="flex flex-col gap-4 font-titleFont font-medium overflow-hidden py-6">
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
                 <Link
                     activeClass="active"
                     to={'home'}
                     spy={true}
                     smooth={true}
                     offset={-70}
                     duration={500}
                 >
              Accueil
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
             <Link
                 activeClass="active"
                 to={'expertise'}
                 spy={true}
                 smooth={true}
                 offset={-70}
                 duration={500}
             >
              Outils de psychothérapie
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
               <Link
                   activeClass="active"
                   to={'forwho'}
                   spy={true}
                   smooth={true}
                   offset={-70}
                   duration={500}
               >
              Pour qui ?
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
              <Link
                  activeClass="active"
                  to={'resume'}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
              >
              Parcours
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                    <li>
            <span className="w-full text-lg relative hover:text-designColor duration-300 group cursor-pointer">
             <Link
                 activeClass="active"
                 to={'podcast'}
                 spy={true}
                 smooth={true}
                 offset={-70}
                 duration={500}
             >
              Podcasts
                 </Link>
              <span
                  className="w-full h-[1px] bg-designColor inline-flex absolute left-0 -bottom-1 -translate-x-[100%] group-hover:translate-x-0 transition-transform duration-300"></span>
            </span>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Footer