import React from 'react'
import {Link} from "react-scroll";
import {FaQuestion} from "react-icons/fa6";

const Card = ({item: {id, title, width, first, media}}) => {
    return (
        <div className={"flex flex-row"}>
            <div
                className="w-full  h-80 py-10 rounded-lg shadow-shadowOne flex items-center bg-gradient-to-r from-bodyColor to-[#202327] group hover:bg-gradient-to-b hover:from-black transition-colors duration-100 group">
                <div className="h-72 overflow-y-hidden">
                    <div className={"flex pl-4 justify-end px-4"}>
                        <span className="cursor-pointer">
                            <Link
                                activeClass="active"
                                to={id}
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                <div className={"px-1 py-1  bg-[#3D303096] rounded-xl hover:bg-[#625050FF]"}>
                                    <FaQuestion className={"text-designColor font-bold "}/>
                                </div>
                            </Link>
                        </span>
                    </div>
                    <div className={"px-12"}>
                        <div
                            className="flex h-full flex-col gap-3 translate-y-2 group-hover:translate-y-0 transition-transform duration-500">
                            <div className={"flex flex-row"}>

                                <div className={width + " h-auto flex flex-col mx-auto"}>
                                    <img src={media} alt="jenny"/>
                                </div>
                            </div>
                            <div className="flex flex-col ">
                                <h2 className="text-xl font-titleFont font-bold text-designColor text-center">
                                    {title}
                                </h2>
                                <p className={"text-textColor mb-2 text-center"}> {first} ... </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Card