export const navLinksdata = [
    {
        _id: 1001,
        title: "Accueil",
        link: "home",
    },
    {
        _id: 1005,
        title: "Outils de Psychothérapie",
        link: "expertise",
    },
    {
        _id: 1002,
        title: "Pour qui ?",
        link: "forwho",
    },
    {
        _id: 1004,
        title: "Parcours",
        link: "resume",
    },
    {
        _id: 1006,
        title: "Podcasts",
        link: "podcast",
    },

];

