import React from 'react';

const Text = ({des}) => {
    return (
        <>
            <div className={"w-full font-titleFont text-justify lg:w-3/4"}>
                {
                    des.map((text) =>
                        (typeof text === "string" ?
                            <p className={"text-textColor mb-2"}> {text} </p> :
                            <ul className={"columns-2 lg:columns-3 pl-3 my-2 text-textColor"}> {text.map((item) => <li> {"\u2022 " + item} </li>)} </ul>
                        )
                    )
                }
            </div>
        </>
    );
};

export default Text;