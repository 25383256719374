import React from 'react';
import Banner1 from "./Banner1";
import Banner2 from "./Banner2";
import {jenny} from "../../assets";
import TextImage from "../layouts/TextImage";

const Banner = () => {
    return (
        <section
            id={"home"}
            className={"w-full h-[1100px] lg:h-[900px] pb-5 items-center flex lg:border-b-[1px]" +
                " font-titleFont lg:border-b-black"}
        >
            <div className={"w-full flex flex-col gap-10 text-center"}>
            <Banner1/>
            <Banner2/>
                <TextImage
                           des={["Jenny Bacry est psychologue clinicienne, psychothérapeute et formatrice depuis trente ans. " +
                           "Elle a été formée à différents outils qu’elle utilise conjointement dans sa pratique de thérapeute : " +
                           "la Psychothérapie analytique, l’Ethnoclinique ainsi que l'Hypnose Ericksonienne et médicale, la PNL " +
                           "(Programmation Neuro Linguistique), l'EFT (Emotional Freedom Techniques), " +
                           "le DRYME (Désensibilisation et Retraitement par les Yeux et leurs Mouvements, des Emotions), " +
                           "la Danse thérapie, le Zéro Mental (technique de centrage), le Mindfulness (méditation pleine conscience), " +
                           "l'Energétique, l'Équithérapie. ",
                               "Elle a mis au point une technique possédant les mêmes propriétés que l'EMDR appelée DRYME " +
                               "(Désensibilisation et Retraitement par les Yeux et leurs Mouvements, des Emotions) permettant " +
                               "d'obtenir rapidement un soulagement face à des émotions négatives ou traumatismes récents " +
                               "ou anciens."
                           ]}
                           media={jenny}
                           flagVertical={true}
                           position = {"left"}
                />
            </div>

        </section>


    );
}
export default Banner;