import {hypnose, dryme, danse, EFT, equi, mindful, PNL, constellations} from "../assets";

export const featuresData = [
    {
        id: 1,
        title: "Hypnose Ericksonienne et médicale",
        first: "L'hypnose est une thérapeutique composée de suggestions directes ou indirectes, de métaphores, de symboles",
        des: ["L'hypnose est une thérapeutique composée de suggestions directes ou indirectes, de métaphores, de " +
        "symboles. Cette technique permet à une personne d'entrer dans un état de conscience modifiée. Cet état " +
        "\"second\" offre à la personne d'aller transformer sa façon de percevoir une situation et d'en modifier " +
        "certaines perceptions comme la douleur ou la peur. La durée de la séance d'hypnose peut aller de quelques " +
        "minutes à près d'une heure, en fonction des techniques utilisées et des besoins de la personne.",
            "L'hypnose se définit par un état de conscience particulier, qui n'est ni un état de sommeil, ni un état " +
            "de veille. C'est par la parole que le praticien induit l'hypnose chez son patient. Celui-ci devient alors " +
            "indifférent à l’extérieur. L’hypnose part du principe que l’ inconscient est particulièrement puissant " +
            "et permet de trouver de nouvelles solutions, comportements, là où le conscient est parfois très limité. " +
            "C'est lui qui gère les fonctions de l'organisme (la respiration, le système digestif et cardiaque, " +
            "l'équilibre lors de la marche...) et qui coordonne les apprentissages et les émotions sans même y " +
            "penser. Il met en œuvre les comportements automatiques.",
            "En séance , l’hypnose permet la reprogrammation de comportements dont le patient souhaite se défaire"],
        media: hypnose,
        link: "hypnose",
        width: "w-8/12"
    },
    {
        id: 2,

        title: "DRYME",
        first: "Le Dryme (Désensibilisation et Retraitement, par les Yeux et leurs Mouvements, des Emotions) repose " +
            "sur les mêmes principes que l'EMDR",
        des: [
            "Le Dryme (Désensibilisation et Retraitement, par les Yeux et leurs Mouvements, des Emotions) repose " +
            "sur les mêmes principes que l'EMDR : le  processus de traitement adaptatif de l’information.",
            "Cette thérapie part du principe que chaque pathologie, symptôme ou problématique, sont générés par des " +
            "événements perturbants, ou vécus comme traumatique par le passé : des événements qui n’auraient pas " +
            "été \"digérés\" ou qui n’auraient pas été traités correctement, et qui ont encore un impact sur le " +
            "comportement dans le présent. Il s’agit d’une thérapie dont la durée et le déroulé va varier d’un " +
            "patient à l’autre. Cette thérapie a des effets simultanés sur le corps, le mental et les émotions et " +
            "ne se réduit pas à la procédure des stimulations bilatérales alternées (notamment les fameux " +
            "\"Mouvements oculaires\")."
        ],
        media: dryme,
        link: "dryme",
        width: "w-6/12"
    },
    {
        id: 3,
        title: "PNL",
        first: "La programmation neurolinguistique (PNL) s’intéresse à la façon dont les humains fonctionnent dans un " +
            "environnement donné",
        des: [
            "La programmation neurolinguistique (PNL) s’intéresse à la façon dont les humains fonctionnent dans un " +
            "environnement donné, et ce, en fonction de leurs schémas comportementaux. " +
            "À travers un ensemble de techniques et de méthodes, la PNL aide à changer la façon dont un individu " +
            "perçoit son environnement. En effet, chaque individu possède une carte du monde qui lui est propre, et qui " +
            "est modulée par ses croyances et ses conditionnements.",
            "Le but de la PNL est de modifier cette carte afin de permettre à l’individu d’atteindre ses objectifs plus efficacement.",
            "La PNL apporte donc les outils nécessaires pour opérer des changements personnels et structurels, et ce, afin de créer de nouveaux potentiels de réussite dans tous les domaines de la vie.\n" +
            "Autrement dit, cela nous amène à voir le verre à moitié plein plutôt qu’à moitié vide. Il n’y a pas " +
            "d’échecs, il n’y a que des expériences qui nous enseignent et nous permettent d’évoluer."
        ],
        media: PNL,
        link: "PNL",
        width: "w-5/12"
    },
    {
        id: 4,
        title: "Mindfulness",
        first: "La Pleine Conscience (mindfuness en anglais) est la conscience qui se manifeste lorsque l'on porte attention " +
            "sur l'expérience du moment présent",
        des: [
            "La Pleine Conscience (mindfuness en anglais) est la conscience qui se manifeste lorsque l'on porte attention " +
            "intentionnellement et de manière non jugeante sur l'expérience du moment présent. La Pleine Conscience " +
            "s'entraîne par la méditation formelle et des pratiques informelles.",
            "Entraîner son attention permet de vivre pleinement, en étant plus présent et plus conscient. La pratique " +
            "de la Pleine Conscience permet de contacter nos ressources internes, notre capacité de résilience au " +
            "stress, notre clarté d'esprit, notre calme intérieur, notre liberté par rapport aux schémas réactifs " +
            "habituels, notre bienveillance. C'est la relation que nous entretenons à nous-mêmes, aux autres et au " +
            "monde qui progressivement peut changer et s'apaiser, en voyant et en comprenant plus clairement ce qui " +
            "est à l'origine de notre stress et de nos insatisfactions."
        ],
        media: mindful,
        link: "Mindfulness",
        width: "w-7/12"
    },
    {
        id: 5,
        title: "Équithérapie ",
        first: "L'Équithérapie " +
            "est un type de traitement expérimental qui implique une personne en thérapie en " +
            "interaction avec des chevaux ",
        des: [
            "La psychothérapie assistée par le cheval, également appelée thérapie équestre ou thérapie par le cheval, " +
            "est un type de traitement expérimental de la santé mentale qui implique une personne en thérapie en " +
            "interaction avec des chevaux. Conçue pour les personnes de tous âges, la thérapie équine s’est avérée " +
            "efficace pour traiter un large éventail de problèmes de santé mentale, en répondant aux préoccupations " +
            "physiques et psychologiques associées à un diagnostic. ",
            "Des études ont montré que des personnes de tous âges peuvent bénéficier de la thérapie assistée par " +
            "l’équitation, et les chevaux ont été intégrés dans le traitement d’une grande variété de problèmes."
        ],
        media: equi,
        link: "equi",
        width: "w-5/12"
    },
    {
        id: 6,
        title: "EFT",
        first: "L'EFT (Emotional Freedom Techniques) est une pratique psycho-énergétique de libération des émotions",
        des: [
            "L'EFT (Emotional Freedom Techniques) est une pratique psycho-énergétique de libération des émotions qui " +
            "utilisent les méridiens énergétiques chinois. En ce sens, c’est une technique qui s’appuie sur certains " +
            "principes issus de l’acupuncture et de l’EMDR (eye movement desensitization and reprocessing). Cet outil " +
            "vise à supprimer les émotions négatives en tapotant des points spécifiques d’acupuncture.  "
        ],
        media: EFT,
        link: "EFT",
        width: "w-7/12"
    },
    {
        id: 7,
        title: "Danse Thérapie",
        first: "En danse-thérapie, le corps devient l'instrument à partir duquel on apprend à être bien dans sa peau ",
        des: [
            "En danse-thérapie, le corps devient l'instrument à partir duquel on apprend à être bien dans sa peau, " +
            "à sortir de sa tête, à retrouver l'énergie vitale. La danse-thérapie vise la prise de conscience de soi " +
            "et la libération de tensions et de blocages inscrits dans la mémoire du corps. ",
            "Sur le plan physique, elle améliore la circulation, la coordination et le tonus musculaire.",
            "Sur le plan mental et émotif, elle renforce l'affirmation de soi, ravive les capacités intellectuelles " +
            "et la créativité, et permet de rencontrer des émotions parfois difficiles à exprimer verbalement : " +
            "colère, frustration, sentiment d'isolement, ..."
        ],
        media: danse,
        link: "danse",
        width: "w-6/12"
    },
    {
        id: 8,
        title: "Constellations familiales individuelles ",
        first: "Nous sommes tous issus d’une famille, nous portons un héritage",
        des: [
            "Nous sommes tous issus d’une famille, nous portons un héritage, et les liens avec les membres des différentes " +
            "générations sont souvent plus forts et plus puissants que nous ne le pensons et peuvent représenter un poids " +
            "invisible qui va empoisonner insidieusement notre vie.",
            "Les Constellations familiales mettent à jour les relations. "+
            "cachées, entre notre histoire familiale et nos dysfonctionnements : souffrances, maladies, difficultés personnelles " +
            "ou professionnelles. Elles montrent de manière évidente, la façon dont nous sommes inconsciemment prisonniers " +
            "des attaches émotionnelles et limitantes qui nous relient à notre système familial, et nous en libèrent."
        ],
        media: constellations,
        link: "constellations",
        width: "w-5/12"
    },

];

