import React from 'react';
import Navbar from "../navbar/Navbar";
import GoUpButton from "../layouts/GoUpButton";
import Banner from "../banner/Banner";
import Resume from "../resume/Resume";
import Expertise from "../expertise/Expertise";
import PodcastsList from "../podcasts/PodcastsList";
import Footer from "../footer/Footer";
import Tools from "../tools/Tools";
import Forwho from "../forWho/Forwho";

const Main = () => {
    return (
        <div className="w-full h-auto bg-bodyColor text-lightText overflow-hidden background: linear-gradient(#e66465, #9198e5)">
            <div className={"mx-auto"}>
                <Navbar/>
            </div>
            <GoUpButton/>
            <div className={"px-4"}>
                <GoUpButton/>
                <Banner/>
                <Expertise/>
                <Forwho/>
                <Resume/>
                <Tools/>
                <PodcastsList/>
            </div>
            <div>
                <Footer/>
            </div>
        </div>
    );
};

export default Main;